import React from "react"
import Container from "../../components/container"
import CustomHeader from "../../components/customHeader"
import SideBarView from "../../components/SideBarView"
import Seo from "../../components/Seo"
import { Typography, Grid } from "@mui/material"
import { CodeBlockView } from "../../components/HelperComponents"

const programmingRoutineView = (data) => {
  return (
    <div style={{ marginTop: "10px", marginBottom: "15px" }}>
      <Typography variant={`h6`}>{data.title}</Typography>
      <CodeBlockView code={data.code && (data.code).trim()} language={data.language}/>
      {data.description}
    </div>
  )
}


const NodeJsTimestampConversions = () => {
  return (
    <Container>
      <CustomHeader title={`NodeJS Timestamp Conversions`}/>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <Typography variant={`p`}>
            Timestamp conversions are a common task when working with date and time in Node.js. Fortunately, Node.js
            provides several libraries for this purpose, and one of the most popular is <b>dayjs</b>.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            <b>dayjs</b> is a lightweight and fast library for parsing, validating, manipulating, and formatting dates
            and
            times. It is similar to the well-known <b>moment.js</b> library, but with a smaller bundle size and faster
            performance.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            Here are some examples of how to use <b>dayjs</b> for timestamp conversions in Node.js:
          </Typography>
          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Convert a timestamp to a formatted date and time string:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const dayjs = require('dayjs');

const timestamp = 1647144063000; // March 12, 2022 10:41:03 AM UTC

const formattedDate = dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss');

console.log(formattedDate); // Output: "2022-03-12 10:41:03"`
          })}
          <Typography variant={`p`}>
            In this example, <b>dayjs(timestamp)</b> creates a <b>dayjs</b> object from the given timestamp, and <b>format('YYYY-MM-DD
            HH:mm:ss')</b> formats it into a string that represents the date and time in the desired format.
          </Typography>
          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Convert a date and time string to a timestamp:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const dayjs = require('dayjs');

const dateString = '2022-03-12 10:41:03';

const timestamp = dayjs(dateString).valueOf();

console.log(timestamp); // Output: 1647144063000`
          })}
          <Typography variant={`p`}>
            Here, <b>dayjs(dateString)</b> creates a <b>dayjs</b> object from the given date and time string,
            and <b>valueOf()</b> returns
            the timestamp in milliseconds.
          </Typography>
          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Convert a timestamp to a different time zone:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

const timestamp = 1647144063000; // March 12, 2022 10:41:03 AM UTC

const convertedTimestamp = dayjs(timestamp).tz('America/New_York').valueOf();

console.log(convertedTimestamp); // Output: 1647128463000`
          })}
          <Typography variant={`p`}>
            In this example, <b>dayjs(timestamp).tz('America/New_York')</b> converts the timestamp to
            the <b>America/New_York</b> time
            zone and returns a <b>dayjs</b> object in that time zone. <b>valueOf()</b> then returns the timestamp in
            milliseconds.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            These are just a few examples of how <b>dayjs</b> can be used for timestamp conversions in
            Node.js. <b>dayjs</b> provides
            many more features for manipulating and formatting dates and times, and it's worth exploring its
            documentation to learn more.
          </Typography>
          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting a Unix Timestamp to a Date String:
          </Typography>
          <Typography variant={`p`}>
            The Unix timestamp represents the number of milliseconds since January 1, 1970, UTC. To convert a Unix
            timestamp to a date string, we can use the <b>dayjs</b> library's <b>format()</b> method. Here's an example:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const dayjs = require('dayjs');

const unixTimestamp = 1647313260000; // March 15, 2022 12:21:00 AM UTC

const dateString = dayjs(unixTimestamp).format('YYYY-MM-DD HH:mm:ss');

console.log(dateString); // Output: '2022-03-15 00:21:00'`
          })}
          <Typography variant={`p`}>
            In this example, we create a dayjs object from the Unix timestamp and then use the <b>format()</b> method
            to
            format the date in the desired format.
          </Typography>

          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting a Date String to a Unix Timestamp:
          </Typography>
          <Typography variant={`p`}>
            To convert a date string to a Unix timestamp, we can use the dayjs library's <b>valueOf()</b> method. Here's
            an
            example:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const dayjs = require('dayjs');

const dateString = '2022-03-15 00:21:00';

const unixTimestamp = dayjs(dateString).valueOf();

console.log(unixTimestamp); // Output: 1647313260000`
          })}
          <Typography variant={`p`}>
            In this example, we create a dayjs object from the date string and then use the <b>valueOf()</b> method to
            get the Unix timestamp in milliseconds.
          </Typography>

          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting a Date String to Another Timezone:
          </Typography>
          <Typography variant={`p`}>
            To convert a date string to another timezone, we can use the dayjs library's <b>tz()</b> method. Here's an
            example:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

const dateString = '2022-03-15 00:21:00';

const convertedDate = dayjs(dateString).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss');

console.log(convertedDate); // Output: '2022-03-14 20:21:00'`
          })}
          <Typography variant={`p`}>
            In this example, we create a dayjs object from the date string and then use the <b>tz()</b> method to
            convert it to
            the <b>America/New_York timezone</b>. Finally, we use the <b>format()</b> method to format the date in the
            desired format.
          </Typography>

          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting a Date Object to a Date String:
          </Typography>
          <Typography variant={`p`}>
            To convert a Date object to a date string, we can use the dayjs library's <b>format()</b> method. Here's an
            example:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const dayjs = require('dayjs');

const dateObject = new Date('2022-03-15T00:21:00.000Z');

const dateString = dayjs(dateObject).format('YYYY-MM-DD HH:mm:ss');

console.log(dateString); // Output: '2022-03-15 00:21:00'`
          })}
          <Typography variant={`p`}>
            In this example, we create a dayjs object from the Date object and then use the <b>format()</b> method to
            format the date in the desired format.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            These are the four types of timestamp conversions that can be performed using <b>dayjs</b> in Node.js.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default NodeJsTimestampConversions

export const Head = () => {
  return (
    <Seo
      title="Mastering Timestamp Conversions in Node.js with dayJs"
      canonicalUrl={"https://www.epochconverter.io/blogs/nodejs-timestamp-conversions"}
    />
  )
}
